<template>
  <div>
    <h1 class="fd-text-lg fd-font-bold fd-text-black">{{ $t('invitations.rs.title') }}</h1>
    <p class="fd-text-sm fd-font-normal" style="color: rgba(0, 0, 0, 0.71);">
      {{ $t('invitations.rs.description') }}
    </p>

    <validation-observer ref="loginValidation">
      <b-form
        class="fd-mt-9"
        @submit.prevent
      >
        <b-form-group
          class="fd-mt-5"
          :label="$t('global.email')"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.email')"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              :state="errors.length > 0 ? false:null"
              name="email"
              :placeholder="$t('global.email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.password')"
          label-for="password"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.password')"
            rules="required"
            vid="password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                :name="$t('global.password')"
                placeholder="••••••••••"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="fd-mt-5"
          :label="$t('global.confirmPassword')"
          label-for="confirmPassword"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('global.confirmPassword')"
            rules="required|confirmed:password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="confirmPassword"
                v-model="form.password_confirmation"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                :name="$t('global.confirmPassword')"
                placeholder="••••••••••"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <div class="fd-absolute fd-bottom-5 fd-right-5 fd-w-full fd-bg-white fd-flex fd-items-center fd-justify-end fd-mt-12">
      <b-button @click="verify" variant="primary">{{ $t('invitations.ana.saveAndContinue') }}</b-button>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormGroup, BFormInput, BForm, BInputGroup, BButton } from 'bootstrap-vue'
import { required, email, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: "Confirm",
  components: { ValidationProvider, ValidationObserver, BForm, BFormGroup, BFormInput, BInputGroup, BButton },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
      },
      required,
      email,
      confirmed
    }
  },
  beforeCreate() {
    if (localStorage.getItem('token') !== null) {
      return this.$router.push({ name: 'projects' })
    }
    if (!this.$route.query.token) {
      this.$router.push({ name: 'login' })
    }
  },
  created() {
    this.form.token = this.$route.query.token
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    verify() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('invitations/verifyAna', this.form).then(() => {
            this.$router.push({ name: 'login' })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invite {
  background: #FAFAFA url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjQ1IiB2aWV3Qm94PSIwIDAgMTAwIDQ1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0Ljg1MyAtNDAuNzUxOEw1MCA0My4xOTkyTC0zNC44NTI4IC00MC43NTE4IiBzdHJva2U9IiNGNEY3RjUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");

  & ::v-deep label {
    color: #5E5873 !important;
  }
}
</style>